exports.components = {
  "component---src-pages-about-old-js": () => import("./../../../src/pages/about-old.js" /* webpackChunkName: "component---src-pages-about-old-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-the-team-js": () => import("./../../../src/pages/the-team.js" /* webpackChunkName: "component---src-pages-the-team-js" */),
  "component---src-pages-why-magento-2-js": () => import("./../../../src/pages/why-magento-2.js" /* webpackChunkName: "component---src-pages-why-magento-2-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-remark-post-js": () => import("./../../../src/templates/remark-post.js" /* webpackChunkName: "component---src-templates-remark-post-js" */)
}

